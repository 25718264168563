//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'

export default {
    name: 'ServiceCategorySelect',
    props: ['value', 'placeholder', 'with_services', 'type', 'v'],
    components: {Multiselect},
    data () {
        return {
            loading: false,
            selected_value: null,
            options: [],
            service_type: null
        }
    },
    methods: {
        getData: debounce(function (query) {
            if(query !== '') {
                this.loading = true;
                this.$axios.post("/get-service-categories", { query: query, service_type_id: this.type ? this.type.id : null, with_services: this.with_services })
                    .then((res) =>{
                        this.options = res;
                        this.loading = false;
                    });
            }
        }, 500),
    },
    mounted() {
        if(this.value){
            this.selected_value = this.value;
        }
        this.getData();
        this.$events.on('changeLocale', eventData => this.getData());
    },
    beforeDestroy() {
        this.$events.$off('changeLocale')
    },
    watch: {
        type() {
            if(this.service_type?.id != this.type?.id) {
                this.options = [];
                this.service_type = this.type
                this.getData();
            }
        },
        value(a) {
            this.selected_value = a;
        },
        selected_value(option) {
            if (!option) {
                this.$emit('input', null);
                this.$emit('setId', null);
            }else {
                this.$emit('input', option);
                this.$emit('setId', option ? option.id : null);
            }
        }
    },
};
