//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Tab',
    props: {
        id: { default: null },
        name: { required: true },
        icon: { default: null },
        dataRef: { default: null },
        prefix: { default: '' },
        suffix: { default: '' },
        isDisabled:{ default: false },
    },
    data: () => ({
        isActive: false,
        isVisible: true,
    }),
    computed: {
        header() {
            return this.prefix + this.name + this.suffix;
        },
        computedId() {
            return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-');
        },
        hash() {
            if (this.isDisabled) {
                return '#';
            }
            return '#' + this.computedId;
        },
    },
};
