//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

export default {
    name: 'AccountProfilePicture',
    props: ['editable'],
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    data () {
        return {
            loading: {
                upload: false,
            },
            image: null,
            hoverDropZone: false
        }
    },
    mounted() {
        this.image = this.user.image;
    },
    watch: {
        'user.image': function (a) {
            this.image = a;
        }
    },
    methods: {
        addImage(e) {
            this.hoverDropZone = false;
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            let current_max_index = 0;
            this.uploadImage(files, 0, current_max_index, e.target);
        },
        uploadImage(files, index, current_max_index, file_input) {
            if (['image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp', 'image/svg+xml', 'image/svg'].indexOf(files[index].type) < 0) {
                this.$toast.error('File type not allowed');
                return;
            }
            let formData = new FormData();
            formData.append('file', files[index]);
            formData.append('target', 'Modules\\Customers\\Entities\\Customer');
            formData.append('id', this.user.id);
            formData.append('replace', true);
            this.loading.upload = true;

            return this.$axios.post("/upload-profile-image", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                emulateJSON: true,
                files: true
            })
                .then((res) =>{
                    this.loading.upload = false;
                    this.image = res.image;
                    this.$forceUpdate();
                })
                .catch((error) => {
                    this.loading.upload = false;
                    this.image = null;
                    this.$forceUpdate();
                }).finally(function () {
                    if (typeof files[index+1] !== 'undefined') {
                        this.uploadImage(files, index+1, current_max_index, file_input);
                    }else if(file_input !== 'undefined'){
                        file_input.value = null
                    }
                });
        }
    }
};
