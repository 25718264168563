//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormGroup from '@/components/FormGroup'
export default {
    name: 'FormInput',
    components: {FormGroup}
}
