//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'

export default {
    name: 'MapViewMobile',
    props: ['filters', 'visible'],

    data () {
        return {
            loading: {
                data: false,
                search: false
            },
            zoom: 10,
            center: [-79.3832, 43.6532],
            layerOptions: {
                accessToken: process.env.MAPBOX_ACCESS_TOKEN,
                style: process.env.MAPBOX_STYLE,
            },
            selectedBusiness: null,
            businesses: [],
        }
    },
    computed: {
        ...mapState({
            position: state => state.position,
        })
    },
    watch: {
        position() {
            if (this.position && !this.filters.city) {
                this.center = [this.position.latitude, this.position.longitude]
            }
        },
        'filters.city': function() {
            if (this.filters.city && typeof this.filters.city.latitude !== 'undefined') {
                this.center = [this.filters.city.latitude, this.filters.city.longitude]
            }
        },
        filters() {
            this.getData()
        },
        visible() {
            if(this.visible) {
                this.map?.resize(false)
                this.init();
            }
        },
        selectedBusiness() {
            if(this.selectedBusiness) {
                this.$modal.show('mapBusinessPopup')
            }else{
                this.$modal.hide('mapBusinessPopup')
            }
        }
    },
    methods: {
        getIcon(item) {
            const selected = this.selectedBusiness && item.id === this.selectedBusiness.id;
            return L.divIcon({
                className: "my-custom-pin",
                iconAnchor: selected ? [13, 40] : [9, 28],
                html: `<svg xmlns="http://www.w3.org/2000/svg" width="${selected ? '26' : '18'}" height="${selected ? '40' : '28'}" viewBox="0 0 9.055 13.53">
                            <g>
                                <path fill="${selected ? '#FD8A63' : '#3fbcd6'}" d="M59.17 0a4.532 4.532 0 0 0-4.527 4.527 4.486 4.486 0 0 0 .438 1.945l.019.039 3.421 6.623a.731.731 0 0 0 1.3 0l3.42-6.621.017-.035a4.485 4.485 0 0 0 .442-1.951A4.532 4.532 0 0 0 59.17 0zm0 6.9a2.37 2.37 0 1 1 2.37-2.37 2.372 2.372 0 0 1-2.37 2.37z" transform="translate(-54.643)"/>
                            </g>
                        </svg>`
            });
        },
        init() {
            if (!this.businesses.length) {
                this.loading.data = true;
                this.getData()
            }
            if (this.filters.city) {
                this.center = [this.filters.city.latitude, this.filters.city.longitude]
            }else if (this.position) {
                this.center = [this.position.latitude, this.position.longitude]
            }
        },
        getData () {
            this.$axios.post("/businesses", { ...this.filters, ...{
                    limit: 500,

                }})
                .then((res) =>{
                    this.businesses = res.businesses.data;
                    this.loading.data = false;
                })
                .catch(()=> {
                    this.loading.data = false;
                });
        },
        zoomIn() {
            this.map.zoomIn()
        },
        zoomOut() {
            this.map.zoomOut()
        },
        onMapLoaded (event) {
            this.map = event.map;
        },
        selectBusiness(business) {
            this.selectedBusiness = business
            this.$modal.show('mapBusinessPopup')
        }
    }
};
