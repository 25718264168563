//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Stars',
    props: ['editable', 'value', 'size'],
    data () {
        return {
            selected_value: null,
            hover_value: null
        }
    },
    mounted() {
        if(this.value){
            this.selected_value = this.value;
        }
    },
    watch: {
        value(a) {
            this.selected_value = a;
        },
        selected_value(option) {
            if (!this.editable) {
                return false;
            }
            if (!option) {
                this.$emit('input', null);
            }else {
                this.$emit('input', option);
            }
        },
    },
};
