//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import dayjs from 'dayjs'
let isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
let isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)


export default {
    name: 'CalendarPicker',
    props: ['value', 'minDate', 'maxDate'],
    data() {
        return {
            calendar: null,
            today: null,
            selected_date: null,
            month: null,
            year: null,
            days: [],
            week_days: [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
            months: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
        }
    },
    mounted () {
        let today = dayjs();
        this.today = today.format('YYYY-MM-DD')
        this.month = today.month();
        this.year = today.year();
        this.selected_date = today.format('YYYY-MM-DD')

        this.generateCalendar()
    },
    watch: {
        month() {
            this.generateCalendar()
        },
        selected_date() {
            this.$emit('change', this.selected_date)
        }
    },
    methods: {
        selectDate(date) {
            if (!date.enabled) {
                return false;
            }
            this.selected_date = date.date;
        },
        setNextMonth() {
            if (this.month === 11) {
                this.month = 0;
                this.year += 1;
            }else{
                this.month += 1;
            }
        },
        setPrevMonth() {
            if (this.month === 1) {
                this.month = 11;
                this.year -= 1;
            }else{
                this.month -= 1;
            }
        },
        generateCalendar () {
            this.days = this.getDays()
        },
        getDays() {
            let firstDay = (new Date(this.year, this.month)).getDay() - 1;
            if (firstDay < 0) {
                firstDay = 6
            }
            let day = 1;
            let results = [];
            let lastDay = new Date(this.year, this.month + 1, 0).getDate()
            let minDate = this.minDate ? dayjs(this.minDate) : null;
            let maxDate = this.maxDate ? dayjs(this.maxDate) : null;

            let month = this.$options.filters.pad(this.month + 1, 2)
            for (let i = 0; i < 6; i++) {
                results.push([])
                for (let j = 0; j < 7; j++) {
                    if (i === 0 && j < firstDay) {
                        results[i].push(null);
                    } else if (day > lastDay) {
                        break;
                    } else {
                        let enabled = (!minDate || dayjs(`${this.year}-${month}-${this.$options.filters.pad(day, 2)}`).isSameOrAfter(minDate, 'date')) && (!maxDate || dayjs(`${this.year}-${month}-${this.$options.filters.pad(day, 2)}`).isSameOrBefore(maxDate, 'date'))
                        results[i].push({
                            day: day,
                            date: `${this.year}-${month}-${this.$options.filters.pad(day, 2)}`,
                            enabled: enabled
                        });
                        day++;
                    }
                }
            }

            return results
        }
    }
}
