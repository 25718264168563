//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs'

export default {
    name: 'BusinessSchedule',
    props: ['schedule'],
    computed: {
        opened () {
            if (!this.schedule) {
                return false;
            }
            let now = dayjs()
            let day_of_week = now.day();
            let start_time = dayjs(`${now.format('YYYY-MM-DD')} ${this.schedule[day_of_week].time_from}`, 'YYYY-MM-DD HH:mm')
            let end_time = dayjs(`${now.format('YYYY-MM-DD')} ${this.schedule[day_of_week].time_to}`, 'YYYY-MM-DD HH:mm')

            return now.isBetween(start_time, end_time)
        },
    }
};
