//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['help', 'label', 'dataClass', 'prepend_text', 'v', 'placeholder', 'value', 'disabled', 'readonly', 'append_text'],
    name: 'FormInputPassword',
    data () {
        return {
            visible: false
        }
    },
}
