//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ReviewModal',
    props: ['businessId', 'bookingId'],
    data() {
        return {
            loading: {
                save: false
            },
            review: {
                rating: 3,
                content: '',
                anonymous: false,
            },
        }
    },
    validations: {
        review: {
            rating: {required},
            content: {required}
        },
    },
    methods: {
        addReview () {
            this.review = {
                rating: 3,
                content: '',
                anonymous: false,
                businessId: this.businessId,
                bookingId: this.bookingId,
            }
        },
        saveReview() {
            this.loading.save = true;
            this.$axios.post("/businesses/review/save", this.review)
                .then(() =>{
                    this.loading.save = false;
                    this.$emit('onSave');
                    this.$events.emit('loadReviews');
                    this.$events.emit('reloadBusinessDetails');
                    this.$events.emit('reloadBusinesses');
                    this.$modal.hide('addReviewModal')
                })
                .catch((error) => {
                    this.loading.save = false;
                });
        },
    }
}
