//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'

export default {
    name: 'AccountDropdown',
    computed: {
        ...mapState({
            user: state => state.user,
            active_bookings_count: state => state.active_bookings_count,
        })
    },
    watch: {
        '$route': function () {
            if (this.$refs['dropdown']) {
                this.$refs['dropdown'].tip.hide()
            }
        },
    },
    methods: {
        logout () {
            this.$dialog.confirm(this.$t('alert_logout'), {
                okText: this.$t('yes'),
                cancelText: this.$t('no')
            })
                .then(() => {
                    this.$auth.destroyToken()
                    this.$acl.change('public');
                    this.$store.commit('logout')
                    this.$router.push({ name: 'home' })
                })
        },
    },
}
