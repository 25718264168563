import { render, staticRenderFns } from "./FormTextarea.vue?vue&type=template&id=2814e216&functional=true&"
import script from "./FormTextarea.vue?vue&type=script&lang=js&"
export * from "./FormTextarea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormGroup: require('/Users/tudorserban/Projects/esse-fe/components/FormGroup.vue').default})
