import { render, staticRenderFns } from "./TermsAcceptanceModal.vue?vue&type=template&id=1ece7d35&"
import script from "./TermsAcceptanceModal.vue?vue&type=script&lang=js&"
export * from "./TermsAcceptanceModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/Users/tudorserban/Projects/esse-fe/components/Btn.vue').default})
