//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
    name: 'PopularBusinesses',
    props: [],
    data () {
        return {
            loading: false,
            data: null
        }
    },
    computed: {
        ...mapState({
            position: (state) => state.position,
        }),
    },
    methods: {
        getData() {
            this.loading = true;
            this.$axios.post("/top-businesses")
                .then((res) =>{
                    this.data = res;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    }
};
