//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  export default {
    name: 'BookingCard',
    props: ['booking', 'selected']
}
