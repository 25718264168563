//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        msg: String,
    },
    data() {
        return {
            index: 0,
            indexes: [0, 1, 2],
            cards: [
                {
                    slide: 0,
                    id: 0,
                    img_url: "/assets/admin/images/pages/login.svg",
                },
                {
                    slide: 1,
                    id: 1,
                    img_url: "/assets/admin/images/pages/login.svg",
                },
                {
                    slide: 2,
                    id: 2,
                    img_url: "/assets/admin/images/pages/login.svg",
                },
            ],
        };
    },
    methods: {
        setIndex(arr) {
            for(let i = 0; i < this.cards.length; i++) {
                this.cards[i].slide = arr[i]
            }
        },
        moveToNextCard () {
            this.indexes.push(this.indexes.shift());
            this.setIndex(this.indexes)
        }
    },
    mounted() {
        this.setIndex(this.indexes)
    }
};
