//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



import { mapState } from 'vuex'

export default {
    name: 'SpecialOffers',
    props: [],
    data () {
        return {
            loading: false,
            data: null
        }
    },
    computed: {
        ...mapState({
            position: (state) => state.position,
        }),
    },
    methods: {
        getData() {
            this.loading = true;
            this.$axios.post("/get-special-offers")
                .then((res) =>{
                    this.data = res.data;
                    this.loading = false;
                });
        },
    },
    mounted () {
        this.$events.on('changeLocale', eventData => this.getData());
        this.getData()
    },
    beforeDestroy() {
        this.$events.$off('changeLocale')
    },
};
