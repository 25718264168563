//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'BusinessServices',
    props: ['businessId', 'businessSlug', 'can_book'],
    data () {
        return {
            loading: {
                data: false
            },
            services: null,
        }
    },
    mounted () {
        this.$events.on('changeLocale', eventData => this.$fetch());
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    beforeDestroy() {
        this.$events.$off('changeLocale')
    },
    async fetch() {
        await this.getData();
    },
    methods: {
        async getData() {
            this.loading.data = true;
            return this.$axios.post("/businesses/services", {id: this.businessId})
            .then((res) =>{
                this.services = res.data;
                this.loading.data = false;
            })
            .catch((err)=> {
                this.loading.data = false;
            });
        }
    },
};
