//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'

export default {
    name: 'ServiceSelect',
    props: ['value', 'placeholder', 'category_id', 'with_category', 'v', 'only_active'],
    components: {Multiselect},
    data () {
        return {
            loading: false,
            selected_value: null,
            options: []
        }
    },
    methods: {
        getData: debounce(function (query) {
            if(query !== '') {
                this.loading = true;
                this.$axios.post("/get-services", { query: query, category_id: this.category_id, with_category: this.with_category, only_active: this.only_active })
                    .then((res) =>{
                        this.options = res;
                        this.loading = false;
                    });
            }
        }, 500),
    },
    mounted() {
        if(this.value){
            this.selected_value = this.value;
        }
        this.getData();
        this.$events.on('changeLocale', eventData => this.getData());
    },
    beforeDestroy() {
        this.$events.$off('changeLocale')
    },
    watch: {
        value(a) {
            this.selected_value = a;
        },
        selected_value(option) {
            if (!option) {
                this.$emit('input', null);
                this.$emit('setId', null);
            }else {
                this.$emit('input', option);
                this.$emit('setId', option ? option.id : null);
            }
        }
    },
};
