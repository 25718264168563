//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
    name: 'TermsAcceptanceModal',
    data () {
        return {
            loading: {
                accept: false,
            },
            terms: {
                new_content: '',
                cms_content: {
                    title: ''
                }
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    created () {
        this.getData()
    },
    methods: {
        getData () {
            this.loading.data = true
            this.$axios.get('/cms/get-updated-terms')
                .then((res) => {
                    if (res.updatedContent) {
                        this.terms = res.updatedContent
                        this.$modal.show('termsAcceptanceModal')
                    }
                    this.loading.data = false
                })
                .catch((err) => {
                    console.log(err)
                    this.loading.data = false
                })
        },
        accept () {
            this.loading.accept = true
            this.$axios.post('/cms/accept-updated-terms', { updated_content_id: this.terms.id })
                .then(() => {
                    this.loading.accept = false
                    this.getData()
                    this.$modal.hide('termsAcceptanceModal')
                })
                .catch((err) => {
                    console.log(err)
                    this.loading.accept = false
                })
        },
    },
}
