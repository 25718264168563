//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { modelListMixin } from '~/mixins/modelListMixin'

export default {
    name: 'BusinessReviews',
    mixins: [modelListMixin],
    props: ['businessId'],
    data () {
        return {
            loading: {
                data: false
            },
            data: {}
        }
    },
    mounted() {
        this.getData();
        this.$events.$on('loadReviews', eventData => this.getData());
    },
    beforeDestroy() {
        this.$events.$off('loadReviews')
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.filters.id = this.businessId;
            this.$axios.post("/businesses/reviews", this.filters)
                .then((res) =>{
                    this.data = res.data;
                    this.loading.data = false;
                })
                .catch((err)=> {
                    this.loading.data = false;
                });
        },
    },
};
