//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'ProfessionalsList',
    props: [],
    data () {
        return {
            loading: false,
            data: []
        }
    },
    methods: {
        getData() {
            this.loading = true;
            this.$axios.post("/get-professionals")
                .then((res) =>{
                    this.data = res;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    }
};
