//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
    name: 'MapView',
    props: ['filters'],
    data () {
        return {
            loading: {
                data: false,
                search: false
            },
            zoom: 10,
            center: [-79.3832, 43.6532],
            layerOptions: {
                accessToken: process.env.MAPBOX_ACCESS_TOKEN,
                style: process.env.MAPBOX_STYLE,
            },
            businesses: [],
        }
    },
    computed: {
        ...mapState({
            position: state => state.position,
        })
    },
    watch: {
        position() {
            if (this.position && !this.filters.city) {
                this.center = [this.position.latitude, this.position.longitude]
            }
        },
        'filters.city': function() {
            if (this.filters.city) {
                this.center = [this.filters.city.latitude, this.filters.city.longitude]
            }
        },
    },
    methods: {
        init() {
            if (!this.businesses.length) {
                this.loading.data = true;
                this.getData()
            }
            if (this.filters.city) {
                this.center = [this.filters.city.latitude, this.filters.city.longitude]
            }else if (this.position) {
                this.center = [this.position.latitude, this.position.longitude]
            }
        },
        getData () {
            this.$axios.post("/businesses", { ...this.filters, ...{
                    limit: 500,

                }})
                .then((res) =>{
                    this.businesses = res.businesses.data;
                    this.loading.data = false;
                })
                .catch(()=> {
                    this.loading.data = false;
                });
        },
        zoomIn() {
            this.map.zoomIn()
        },
        zoomOut() {
            this.map.zoomOut()
        },
        onMapLoaded (event) {
            this.map = event.map;
        },
    }
};
