var render = function (_h,_vm) {var _c=_vm._c;return _c('formGroup',{staticClass:"form-input",class:[_vm.data.staticClass],attrs:{"help":_vm.props.help,"label":_vm.props.label}},[_c('div',{staticClass:"input-group"},[(!!_vm.$slots.prepend)?_c('span',{staticClass:"input-group-text"},[_vm._t("prepend")],2):(_vm.props.prepend_text)?_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.props.prepend_text))]):_vm._e(),_vm._v(" "),_c('input',{staticClass:"form-control",class:{'is-invalid' : _vm.props.v ? _vm.props.v.$error : null, 'no-label': !_vm.props.label},attrs:{"type":_vm.props.type ? _vm.props.type: 'text',"placeholder":_vm.props.placeholder,"disabled":_vm.props.disabled,"readonly":_vm.props.readonly},domProps:{"value":_vm.props.value},on:{"input":function ($event) {
                   _vm.listeners.input && _vm.listeners.input($event.target.value);
                   if(_vm.props.v){
                       _vm.props.v.$touch();
                   }
               },"change":function ($event) {
                   _vm.listeners.change && _vm.listeners.change($event.target.value);
               },"keyup":function($event){_vm.listeners.keyup && _vm.listeners.keyup($event)},"keypress":function($event){_vm.listeners.keypress && _vm.listeners.keypress($event)},"keydown":function($event){_vm.listeners.keydown && _vm.listeners.keydown($event)}}}),_vm._v(" "),(!!_vm.$slots.append)?_c('span',{staticClass:"input-group-text"},[_vm._t("append")],2):(_vm.props.append_text)?_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.props.append_text))]):_vm._e()]),_vm._v(" "),_vm._t("help")],2)}
var staticRenderFns = []

export { render, staticRenderFns }