//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'MapPosition',
    props: ['loading', 'latitude', 'longitude', 'height', 'width', 'draggable'],
    data () {
        return {
            marker: null,
            zoom: 10,
            center: [0, 0],
            tileLayerClass: (url, options) => this.$L.mapboxGL(options),
            layerOptions: {
                accessToken: process.env.MAPBOX_ACCESS_TOKEN,
                style: process.env.MAPBOX_STYLE,
                // style: 'mapbox://styles/mapbox/streets-v9',
                // style: 'https://raw.githubusercontent.com/osm2vectortiles/mapbox-gl-styles/master/styles/bright-v9-cdn.json'
            },
            // url: 'https://api.mapbox.com/styles/v1/sherby88/ckzpu62xt000215mop4xw4c7o/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2hlcmJ5ODgiLCJhIjoiY2t6cHU0ZzJ6MDExaDJ1cGtidm9yaWNqbyJ9.VORsxvCLwEP1ulYodHcwUg',
            // url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
            attribution: ' ',
            icon: this.$L.icon({
                iconUrl: '/assets/client/images/location-primary.svg',
                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
        }
    },
    created() {
        if(this.latitude && this.longitude) {
            this.center = [this.latitude - 0.0015, this.longitude + 0.004]
        }
    },
    watch: {
        latitude() {
            this.center = [this.latitude - 0.0015, this.longitude + 0.004]
        },
        longitude() {
            this.center = [this.latitude - 0.0015, this.longitude + 0.004]
        }
    }
};
