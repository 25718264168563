//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'ExpandableSection',
    props: ['initial', 'title'],
    data() {
        return {
            expanded: false
        }
    },
    created() {
        if (typeof this.initial != 'undefined') {
            this.expanded = this.initial
        }
    }
}
